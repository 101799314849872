<template>
  <div class="activate-brand" ref="activateBrand">
      <artmisads-modal
        v-model:open="props.showActivateBrand"
        title="Activate Brand"
        :closable="false"
        :width="600"
        :getContainer="() => activateBrand"
        :destroyOnClose="true"
        :maskClosable="false"
      >
      <a-spin :spinning="spinning">
        <p class="text-a">Are you sure you want to activate this brand? This will allow you to activate products for this brand and add them to the Creator marketplace.</p>
        <div class="select-brand">
        <div class="checkbox">
          <a-checkbox
            :disabled="item.active"
            :checked="checkedList.find(i => i === item.value)"
            :key="item.id"
            v-for="item in brandOptions"
            @change="e => changeBrand(e, item.value)"
          >{{item.label}}</a-checkbox>
        </div>
        </div>
        <p class="text-b">I acknowledge that activating this brand will enable my seller account in United States. Once activated, I will be active in the following countries: United States and will have used 1 of my available 1 countries</p>
    </a-spin>
        <template #footer>
          <artmisads-button @click="onCancel">Cancel</artmisads-button>
          <artmisads-button :disabled="spinning || isAllActive" @click="handleOk" type="primary">Confirm</artmisads-button>
        </template>
      </artmisads-modal>
  </div>
</template>

<script setup>

  import { ref, computed, onBeforeMount, watch } from 'vue';

  import { adminApi } from '../../server/index'

  const spinning = ref(false);
  const props = defineProps(['setShowActivateBrand', 'showActivateBrand']);
  const activateBrand = ref(null);
  const checkedList = ref([]);
  const brandList = ref([]);
  const brandOptions = computed(() => {
    let brandOptionsData = [...brandList.value];
    if (brandOptionsData && brandOptionsData.length) {
      brandOptionsData = brandOptionsData.map(item =>( {
        label: item.amazonBrandName,
        value: item.id,
        active: item.status === 'active'
      }))
    }
    return brandOptionsData;
  });

  const isAllActive = computed(() => {
    let active = !brandList.value.find(item => item.status === 'inactive');
    return active;
  })

  const onCancel = () => {
    props.setShowActivateBrand(false);
    location.href = '/#/admin/brand/index';
  }

  const okBtnType = computed(() => {
    const btnType = checkedList.value.length ? 'primary' : 'ghost';
    return btnType;
  })

  watch(() => props.showActivateBrand, (newValue) => {
    if (newValue) {
      getSellerBrands();
    }
  })
  const getSellerBrands = async () => {
    spinning.value = true;
    const res = await adminApi.getSellerBrands();
    const { success, data } = res;
    if (success) {
      brandList.value = data;
      checkedList.value = data.filter(item => item.status === 'active').map(item => item.id);
    }
    spinning.value = false;
  }

  const changeBrand = (e, value) => {
    const checked = e.target.checked;
    if (checked) {
      checkedList.value = [...checkedList.value, value];
    } else {
      checkedList.value = checkedList.value.filter(item => item !== value);
    }
  }

  const handleOk = async e => {
    if (!checkedList.value.length) return;
    spinning.value = true;
    const params = checkedList.value.map(item => Number(item));
    const res = await adminApi.activateBrands(params);
    const { success } = res;
    if (success) {
      props.setShowActivateBrand(false);
      location.href = '/#/admin/product';
    }
    spinning.value = false;
  };

</script>

<style lang="less" scoped>
  .activate-brand {
    .text-a {
      color: var(--dark-3);
      line-height: 20px;
    }
    .select-brand {
      margin-top: 24px;
      margin-bottom: 24px;
      .checkbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        :deep(.ant-checkbox-wrapper) {
          padding-left: 10px;
          display: flex;
          width: 176px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #ada1cc;
          box-shadow: 0 1px 0 0 #1a16250d;
          border-radius: 8px;
          > span {
            align-self: center;
          }
          &:nth-child(n+4) {
            margin-top: 10px;
          }
        }
      }
    }
    .text-b {
      color: var(--dark-2);
      line-height: 20px;
    }
  }
</style>